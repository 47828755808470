import React, { useState } from 'react';

const SubmissionForm = () => {
  const [name, setName] = useState('');
  const [idea, setIdea] = useState('');

  const generateiMessageLink = (message) => {
    const phoneNumber = "18157615790"; // Replace with your desired phone number
    const encodedMessage = encodeURIComponent(message);
    return `sms:${phoneNumber}&body=${encodedMessage}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `Name: ${name}\nIdea: ${idea}`;
    const iMessageUrl = generateiMessageLink(message);
    window.location.href = iMessageUrl;
  };

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4 text-green-600" style={{ fontFamily: '"Satisfy", cursive', fontSize: '36px' }}>Submit Your Idea</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="name" 
            type="text" 
            placeholder="Your Name" 
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idea">
            Idea
          </label>
          <textarea 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
            id="idea" 
            placeholder="Your Idea" 
            required
            value={idea}
            onChange={(e) => setIdea(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-between">
          <button 
            className="bg-pink-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
            type="submit"
          >
            Submit (to Jesus)
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubmissionForm;