import React from 'react';

const leaderboardData = [
  { rank: 1, name: "Fernando", idea: "Topo Gigio", avatar: "/img/fernando.jpg" },
  { rank: 2, name: "Katie", idea: "Shrek", avatar: "/img/katie.jpg" },
  { rank: 3, name: "Matt", idea: "Mark McGrath", avatar: "/img/matt.jpg" },
  { rank: 4, name: "Henry", idea: "Also Mark McGrath", avatar: "/img/henry.jpg" },
  { rank: 5, name: "Nels", idea: "", avatar: "/img/nels.jpg" },
  { rank: 6, name: "Natalie", idea: "", avatar: "/img/natalie.jpg" },
  { rank: 7, name: "Neil", idea: "", avatar: "/img/neil.png" },
  { rank: 8, name: "Saige", idea: "", avatar: "/img/saige.jpg" },
  { rank: 9, name: "Isis", idea: "", avatar: "/img/isis.jpg" },
  { rank: 10, name: "Mom", idea: "No tattoo", strikethrough: true, avatar: "/img/mom.jpg" },
];

const Leaderboard = () => {
  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <table className="w-full border-collapse border border-gray-300 bg-white shadow-lg">
        <thead>
          <tr className="bg-pink-500 text-white">
            <th className="border border-gray-300 p-2">Rank</th>
            <th className="border border-gray-300 p-2">Name</th>
            <th className="border border-gray-300 p-2">Idea</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((entry) => (
            <tr key={entry.rank} className="hover:bg-gray-100">
              <td className="border border-gray-300 p-2 text-center font-bold">{entry.rank}</td>
              <td className="border border-gray-300 p-2 text-pink-600">
                <div className="flex items-center">
                  <img 
                    src={entry.avatar} 
                    alt={`${entry.name}'s avatar`} 
                    className="w-10 h-10 rounded-full mr-3 object-cover"
                  />
                  {entry.name}
                </div>
              </td>
              <td className="border border-gray-300 p-2">{entry.idea}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;