import React from 'react';
import Leaderboard from './components/Leaderboard';
import SubmissionForm from './components/SubmissionForm';
import './index.css';

function App() {
  return (
    <div className="min-h-screen bg-cover bg-center" style={{backgroundImage: "url('/img/background.jpg')"}}>
      <div className="container mx-auto p-4 bg-gray-100 bg-opacity-80">
        <img
          src="/img/banner.gif"
          alt="Sparkly banner"
          className="mb-4 w-full max-w-xl mx-auto"/>
        <Leaderboard />
        <SubmissionForm />
      </div>
    </div>
  );
}

export default App;